import React from 'react';
// import { Link } from 'gatsby';
import { Container, Grid, Icon, Image } from 'semantic-ui-react';

import Layout from '../components/layout';
import Header from '../components/header';
import SEO from '../components/seo';

import './contact-us.css';

import SocialMediaImage1 from '../images/social/facebook-black.png';
import SocialMediaImage2 from '../images/social/instagram-black.png';
// import TeamMemberImage1 from '../images/about-us-chong.png';
// import TeamMemberImage2 from '../images/about-us-tony.png';
// import TeamMemberImage3 from '../images/about-us-mike.png';

const ContactUs = () => (
  <Layout>
    <SEO title="About Us" />
    <Header />
    <Container className="container__ket open-sans-regular" text textAlign="left">
      We work directly with law enforcement, distributors, and retailers. If you are
      interested in working together, or simply have questions about the product, contact
      us:
    </Container>
    <Container className="contact-us-social" text textAlign="center">
      <Grid>
        <Grid.Row columns={6} centered={true}>
          <Grid.Column textAlign="center" verticalAlign="middle">
            <a href="https://www.facebook.com/kineticenergytoolsllc/">
              <Image
                alt="Visit us on Facebook"
                centered={true}
                size="mini"
                src={SocialMediaImage1}
                title="Visit us on Facebook"
              />
            </a>
          </Grid.Column>
          <Grid.Column textAlign="center" verticalAlign="middle">
            <a href="https://www.instagram.com/kineticenergytools/">
              <Image
                alt="Visit us on Instagram"
                centered={true}
                size="mini"
                src={SocialMediaImage2}
                title="Visit us on Instagram"
              />
            </a>
          </Grid.Column>
          <Grid.Column textAlign="center" verticalAlign="middle">
            <a href="mailto:sales@ket-us.com">
              <Icon name="mail" size="large" />
            </a>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
    {/* <Container textAlign="center">
      <div className="contact-us-email-link">
        <a href="mailto:sales@ket-us.com">Mike Weiss</a>
      </div>
      <div className="contact-us-email-link">
        <a href="mailto:sales@ket-us.com">sales@ket-us.com</a>
      </div>
    </Container> */}
    <Container className="container__ket open-sans-regular" text textAlign="left">
      Our business was initially built from a network of friends with a key interest in
      common (you guessed it&mdash;firearms). As the team began rolling up our sleeves
      together, we realized that there were a few, even more important beliefs that we had
      in common - a sense of commitment to creating products that are 100% made in USA,
      exceptionally engineered, and in support of creating a sense of community among
      performance-focused shooters.
    </Container>
    <Container
      className="container__ket__padded-bottom open-sans-regular"
      text
      textAlign="left"
    >
      Our vision is based on growing in a sustainable and honest way. We want to work with
      bad-ass shooters of all types. We all are raising bad-ass daughters and want to
      ensure they are influenced and raised to be strong, independent beings, which is why
      we have special interest in supporting and elevating female shooters within our
      products and video.
    </Container>
    {/* <div className="container__ket-blue container__ket__padded-bottom open-sans-regular">
      <Container text>
        <Grid>
          <Grid.Row columns={3} style={{ padding: '2em 0 1em' }}>
            <Grid.Column textAlign="center">
              <Link to="/contact">
                <p>
                  <Image alt="Chong Lam" src={TeamMemberImage1} title="Chong Lam" />
                </p>
                <p>Chong Lam</p>
              </Link>
            </Grid.Column>
            <Grid.Column textAlign="center">
              <Link to="/contact">
                <p>
                  <Image alt="Tony LaVigna" src={TeamMemberImage2} title="Tony LaVigna" />
                </p>
                <p>Tony LaVigna</p>
              </Link>
            </Grid.Column>
            <Grid.Column textAlign="center">
              <Link to="/contact">
                <p>
                  <Image alt="Mike Weiss" src={TeamMemberImage3} title="Mike Weiss" />
                </p>
                <p>Mike Weiss</p>
              </Link>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </div> */}
    <div class="contact-us-divider" />
  </Layout>
);

export default ContactUs;
